$teal: hsl(178, 34%, 36%);
$teal-light: hsl(178, 51%, 53%);
$teal-medium-1: hsl(178, 34%, 41%);
$teal-medium-2: hsl(178, 34%, 37%);
$teal-dark: hsl(178, 34%, 27%);
$teal-darker: hsl(178, 34%, 19%);

$lighter-beige: hsl(46, 14%, 76%);
$light-beige: hsl(46, 79%, 95%);
$dark-beige: hsl(46, 26%, 85%);
// $dark-beige: $light-beige;
$dark-blue: hsl(211, 33%, 26%);
$darker-blue: hsl(211, 33%, 15%);
$brownish-gray: hsl(47, 6%, 29%);
$blueish-gray: hsl(210, 17%, 44%);
$lighter-blueish-gray: hsl(207, 12%, 40%);
$lighter-gray: hsl(210, 4%, 78%);
$dark-gray: hsl(0, 0%, 39%);
$gray: hsl(0, 0%, 35%);
$light-gray: hsl(0, 0%, 60%);
$black: hsl(0, 0%, 10%);
$light-black: hsl(210, 13%, 13%);
$lighter-white: hsl(210, 63%, 97%);
