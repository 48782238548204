@use './mixins.module' as mixins;
$background-light: $light-beige;
$background-dark: $dark-beige;
$background-dark-2: $dark-blue;
$title-color: $dark-blue;
$text-color-logos: $dark-blue;
$text-color: $brownish-gray;

$nav-root-color: $teal-medium-2;
$nav-border-color: $lighter-gray;

$pin-background-color-past: $teal-medium-2;
$pin-background-color-now: $teal;
$pin-background-color-future: $lighter-white;

$header-height-from-sm-to-md: 0rem;
$header-height-from-lg: 0rem;

// Global
:root {
  font-size: 14px;
  @media (min-width: 800px) and (min-height: 500px) and (max-width: 1280px) and (max-height: 800px) {
    font-size: 12px;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favour of better control in authored CSS */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-wrap: balance;
}

h1 {
  font-size: $font-size-xxxlarge !important;
  line-height: 3rem !important;
  font-family: 'Titillium Web', sans-serif !important;

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    font-size: $font-size-display !important;
    line-height: 3rem !important;
  }
}

h2 {
  font-size: $font-size-xxlarge !important;
  line-height: 2rem !important;
  font-family: 'Titillium Web', sans-serif !important;

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    font-size: $font-size-xxxlarge !important;
    line-height: 2.5rem !important;
  }
}

h3 {
  font-size: calc($font-size-large + 0.25rem) !important;
  font-family: 'Titillium Web', sans-serif !important;
  line-height: calc($font-size-large + 0.5rem) !important;

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    font-size: calc($font-size-xlarge + 0.5rem) !important;
    line-height: calc($font-size-xlarge + 1rem) !important;
  }
  & > a {
    font-size: inherit !important;
  }
}

button,
a {
  font-family: 'Titillium Web', sans-serif !important;
  font-size: $font-size-medium !important;
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    font-size: $font-size-large !important;
  }
}

p,
figure,
li,
figcaption,
span,
blockquote,
dl,
dd {
  font-size: $font-size-large !important;
  line-height: 20px !important;
  padding-inline-end: 0.5rem;
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    font-size: $font-size-xlarge !important;
    line-height: 28px !important;
  }
  font-family: 'Titillium Web', sans-serif !important;
  margin: 0;
  text-wrap: balance;
  text-wrap: pretty;
}

p > a {
  font-size: inherit !important;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul,
ol {
  padding: unset;
  list-style: none;
}

/* Set core body defaults */
#__next,
body {
  line-height: 1.5;
}

main.main {
  padding-block-start: $header-height-from-sm-to-md;
  margin-block-end: auto;
  display: flex;
  flex-direction: column;
  max-width: 100dvw;
  // margin: auto;
  // max-width: 87.5rem;

  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    padding-block-start: $header-height-from-lg;
  }
}

/* Set shorter line heights on headings and interactive elements */
h1,
h2,
h3,
h4,
button,
input,
label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}
html {
  font-size: $font-size-regular;
  overflow: hidden;
  scroll-behavior: smooth;
  :focus,
  .form-check [type='checkbox']:focus + label,
  .form-check [type='radio']:focus + label,
  .toggles label input[type='checkbox']:focus + .lever,
  .form-check
    .toggles
    label
    input[type='checkbox']:focus
    + [type='checkbox'].lever
    + label,
  .toggles
    label
    .form-check
    input[type='checkbox']:focus
    + [type='checkbox'].lever
    + label,
  .form-check
    .toggles
    label
    input[type='checkbox']:focus
    + [type='radio'].lever
    + label,
  .toggles
    label
    .form-check
    input[type='checkbox']:focus
    + [type='radio'].lever
    + label,
  .toggles
    label
    input[type='checkbox']:focus
    + input[type='checkbox'].lever
    + .lever {
    border-color: $primary;
    box-shadow: 0px 0px 0px 0px $primary;
  }
}

// 404 / 500
.error-pages {
  &_section {
    padding-inline: 1rem;
    padding-block: 4rem;
    background-color: $background-light;
    &_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      min-height: 25dvh;
      &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        &_title {
          color: $title-color;
          text-align: center;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 500;
          margin-bottom: unset;
        }
        &_text {
          color: $title-color;
          font-size: $font-size-xlarge;
          line-height: 2rem;
          font-weight: 300;
          margin-bottom: unset;
        }
        &_button {
          margin-block-start: 1rem;
        }
      }
    }
  }
}

// ContactsWithMap
.contacts-map {
  &_section {
    padding-inline: $mobile-inline-spacing;
    padding-block: $mobile-block-spacing;
    background-color: $background-light;
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 87.5rem;
    margin: auto;

    &_text-wrapper {
      display: flex;
      flex-direction: column;

      &_title {
        color: $title-color;
        text-align: left;
        font-size: $font-size-xxlarge;
        font-weight: 700;
        margin-bottom: 2rem;
        word-break: break-all;
      }

      &_richtext {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
        justify-content: flex-start;

        & > p {
          color: $text-color;
          text-align: left;
          font-size: $font-size-large;
          font-weight: 400;
          margin-bottom: unset;
        }
      }
    }
    &_map-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 33rem;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    &_wrapper {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_text-wrapper {
        max-width: 30%;
        &_title {
          font-size: $font-size-xxlarge;
        }

        &_richtext {
          & > p {
            font-size: $font-size-large;
          }
        }
      }
      &_map-wrapper {
        overflow: hidden;
        width: 60%;
        height: 33.75rem;
      }
    }
  }
}

// BigParagraph
.big-paragraph {
  &_section {
    padding-inline: $mobile-inline-spacing;
    background-color: $background-light;
    & > section {
      margin: auto;
      max-width: 87.5rem;
      padding-inline: unset;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
    }
  }
}

// NavScroll
.side-nav-scroll {
  &_section {
    &_container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-height: unset;
      overflow-y: unset;
      row-gap: 1rem;
      padding-block-end: 2rem;

      &_navbar {
        padding: unset;
        border-right: 1px solid $lighter-gray;
        & > div {
          width: 100%;
        }
      }

      &_title {
        color: $title-color;
        font-size: $font-size-xxlarge;
        font-weight: 700;
        line-height: 2.5rem;
        padding-block: 2.5rem 2rem;
      }

      &_content {
        overflow-y: auto;
        max-height: 75dvh;
        border-radius: 0.5rem;
        padding: 0.25rem;
        &_sub-paragraph {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding-block-start: 2.5rem;
          &.nested {
            padding-block: unset;
          }

          &_title {
            font-size: $font-size-xlarge;
            font-weight: 600;
            line-height: 2rem;
            color: $title-color;
            margin: unset;
          }
          &_text {
            color: $text-color;
            font-size: $font-size-medium;
            line-height: 1.75rem;
            font-weight: 400;
            margin: unset;
          }
        }
        &_title {
          font-size: $font-size-xxlarge;
          font-weight: 700;
          line-height: 3rem;
          color: $title-color;
          margin: unset;
          margin-block-end: 1rem;
        }
        &_intro {
          color: $text-color;
          font-size: $font-size-medium;
          line-height: 1.75rem;
          font-weight: 400;
          margin: unset;
          margin-block-start: 3rem;
        }
      }
    }
    .navbar.it-navscroll-wrapper {
      background-color: $background-light;
      max-width: unset;
      width: 100%;
      h2 {
        font-size: $font-size-medium;
        color: $title-color;
        font-weight: 700;
        line-height: 1.5rem;
        text-transform: uppercase;
        margin: unset;
        padding: 1rem;
      }

      .menu-wrapper {
        background-color: transparent;
        padding: unset;
        width: 100%;
        .link-list-wrapper .link-list:after {
          width: 0px;
        }

        .link-list-wrapper ul li a {
          padding: 0.5rem;
          display: inline-flex;
          align-items: center;
          width: 100%;
          &:active,
          &:target,
          &.selected {
            &[data-nesting-level='1'] {
              &::before {
                margin-right: -1rem;
              }
            }
            &[data-nesting-level='2'] {
              &::before {
                margin-right: -2rem;
              }
            }
            &[data-nesting-level='3'] {
              &::before {
                margin-right: -3rem;
              }
            }
            &[data-nesting-level='4'] {
              &::before {
                margin-right: -4rem;
              }
            }
            &::before {
              content: '';
              position: absolute;
              right: 0;
              border: 1px solid $dark-blue;
              height: 100%;
              width: 1px;
            }
          }

          .chevron {
            transition: all 0.3s ease-in-out;
            color: $brownish-gray;
            margin-left: 0.5rem;
            &[aria-expanded='true'] {
              transform: rotate(180deg);
            }
          }
          &:hover:not(.disabled) .icon {
            fill: $brownish-gray;
          }

          span {
            margin-right: 0.5rem;

            &.node-root {
              &_span {
                color: $nav-root-color;
                font-size: $font-size-medium;
                font-weight: 700;
              }
            }

            &.node-leave {
              &_span {
                color: $text-color;
                font-size: $font-size-medium;
                font-weight: 400;
                line-height: 1.75rem;
              }
            }
          }
        }
        .link-list {
          margin: unset;
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    &_section {
      &_container {
        flex-direction: row;
        padding-block: 3rem;
        column-gap: 2rem;
        width: 100%;
        margin: unset;
        justify-content: space-between;
        max-height: 50rem;
        overflow-y: auto;
        &_navbar {
          border-right: unset;
          border-left: 1px solid $lighter-gray;
          width: 30%;
          flex-shrink: 1;
        }

        &_content {
          width: 70%;
          overflow-y: unset;
          box-shadow: unset;
          &_intro {
            font-size: $font-size-large;
            line-height: 2rem;
          }
          &_sub-paragraph {
            &_title {
              font-size: $font-size-xlarge;
              line-height: 2rem;
            }
            &_text {
              font-size: $font-size-large;
              line-height: 2rem;
            }
          }
        }
      }
      .navbar.it-navscroll-wrapper {
        width: 30%;
        .menu-wrapper {
          .link-list-wrapper ul li a {
            padding-inline: 0.5rem 1rem;
            padding-block: 1rem;
            &:active,
            &:target,
            &.selected {
              &[data-nesting-level='1'] {
                &::before {
                  margin-left: -1rem;
                }
              }
              &[data-nesting-level='2'] {
                &::before {
                  margin-left: -2rem;
                }
              }
              &[data-nesting-level='3'] {
                &::before {
                  margin-left: -3rem;
                }
              }
              &[data-nesting-level='4'] {
                &::before {
                  margin-left: -4rem;
                }
              }
              &::before {
                content: '';
                position: absolute;
                left: 0;
                border: 1px solid $dark-blue;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

// Tag
.tag {
  &_section {
    display: inline-block;
    &_tag {
      display: flex;
      flex-wrap: nowrap;
      font-size: $font-size-medium !important;
      line-height: 1.5rem !important;
      font-weight: 600;
      padding-block: 0.25rem;
      padding-inline: 1rem;
      border-radius: 0.25rem;
      text-transform: capitalize;
      @include mixins.text-shorten(1);
      &.primary {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

// DateSpan
.date-span {
  &_section {
    display: inline;
    &_date {
      font-family: 'Roboto Mono', monospace;
      font-size: $font-size-small;
      font-weight: 400;
      font-size: $font-size-medium !important;
      line-height: 1.5rem !important;
      color: $text-color;
      margin: unset;
    }
  }
}

// FAQ
.faq {
  &_section {
    background-color: $background-dark;
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;
    &_container {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      justify-content: center;
      max-width: 87.5rem;
      margin-inline: auto;
      &_paragraph {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-block: 2.5rem 1.5rem;
        &_title {
          color: $title-color;
          text-align: left;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          margin-bottom: unset;
        }
        &_description {
          color: $text-color;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
        }
      }
      &_faq-list {
        display: flex;
        flex-direction: column;
        gap: unset;
        width: 100%;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-block: $desktop-block-spacing 3rem;
      padding-inline: $desktop-inline-spacing;
      &_container {
        gap: 5rem;
        align-items: flex-start;
        &_paragraph {
          // max-width: 45%;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }
      }
    }
  }
}

// FullVideo
.full-video {
  &_section {
    padding-inline: 1rem;
    padding-block: 3rem 4rem;
    background-color: $background-dark-2;
    &_container {
      aspect-ratio: 16/9;
      // max-width: 70.5rem;
      margin: 0 auto;
      max-width: 87.5rem;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-block: 5rem;
      padding-inline: 11%;
    }
  }
}

// TabText
.tab-text {
  &_section {
    max-width: 87.5rem;
    padding-block: $mobile-block-spacing;
    padding-inline: $mobile-inline-spacing;
    &_container {
      &_paragraph {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-block: 0.5rem 1.5rem;

        &_title {
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          margin-bottom: unset;
          color: $title-color;
        }
        &_description {
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
          color: $text-color;
        }
      }
    }
  }

  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-block: $desktop-block-spacing;
      padding-inline: $desktop-inline-spacing;
      margin: auto;
      &_container {
        &_paragraph {
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
            // max-width: 34.5rem;
          }
        }
      }
    }
  }
  @media (min-width: 87.5rem) {
    &_section {
      padding-inline: 0;
    }
  }
}
// Tabs
.nav {
  &-tabs {
    background-color: $background-light;
    border-bottom: none;
    margin-bottom: 2rem;
    overflow: unset;
    .nav-link {
      border-bottom: 1px solid $text-color;
      color: $title-color;
      font-size: $font-size-regular;
      font-weight: 600;
      line-height: 1.5rem;
      width: fit-content;
      max-width: 20rem;
      text-align: center;
      & > span {
        max-width: 20rem;
        text-wrap: balance;
      }
      &.active,
      .nav-tabs .nav-item.show .nav-link {
        border-bottom: 3px solid $teal-light;
        color: $title-color;
        &:hover {
          text-decoration: unset;
        }
      }
      &:hover {
        border-bottom-color: $teal-light;
        text-decoration: underline;
        color: $title-color;
      }
    }
    .nav-item {
      flex: 1;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &-tabs {
      gap: 1rem;
      .nav-item {
        flex: unset;
      }
    }
  }
}
.tabs {
  &_section {
    &_header {
      flex-wrap: wrap;
      max-width: 100%;
      & span {
        font-size: $font-size-large;
      }
    }
    &_body {
      &_abstract {
        margin-bottom: 2.5rem;
        &_text {
          color: $text-color;
          font-size: $font-size-regular;
          line-height: 1.5rem;
          font-weight: 400;
          margin: unset;
        }
      }
      &_content {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        &_item {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          align-items: flex-start;
          padding-block-end: 1.5rem;
          margin-bottom: 1.5rem;
          border-bottom: 1px solid $brownish-gray;
          &_title {
            color: $title-color;
            font-size: $font-size-large;
            line-height: 1.5rem;
            font-weight: 600;
            margin: unset;
          }
          &_text {
            color: $text-color;
            font-size: $font-size-regular;
            line-height: 1.5rem;
            font-weight: 400;
            margin: unset;
            max-width: 95%;
            > p {
              margin: unset;
            }
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      &_body {
        &_abstract {
          margin-bottom: 6rem;
          max-width: 50%;
          &_text {
            font-size: $font-size-large;
            line-height: 2rem;
          }
        }
        &_content {
          display: flex;
          flex-direction: row;
          column-gap: 1.875rem;

          &_column {
            display: flex;
            flex-direction: column;
            max-height: fit-content;
            width: 50%;
          }

          &_item {
            min-height: 11.5rem;
            width: 100%;
            margin-bottom: 1.5rem;
            max-height: min-content;
            transition: max-height 0.3s ease-in-out;

            &_title {
              font-size: $font-size-large;
              line-height: 1.5rem;
            }
            &_text {
              font-size: $font-size-medium;
              line-height: 1.75rem;
            }
          }
        }
      }
    }
  }
}

// Timeline
.timeline {
  &-item {
    &_card {
      background-color: transparent;
      &_body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        margin-inline-start: 2.5rem;
        max-width: 22.5rem;
        &_title#title {
          color: $title-color;
          font-size: $font-size-large;
          line-height: 1.5rem;
          font-weight: 600;
          margin: unset;
          width: 100%;
        }
        &_description {
          color: $brownish-gray;
          font-size: $font-size-regular;
          line-height: 1.5rem;
          font-weight: 400;
          margin: unset;
        }
        &_button#button {
          font-size: $font-size-small;
          line-height: 1rem;
          font-weight: 700;
          margin-block-start: 0.5rem;
        }
        &_image {
          min-width: 8rem;
          aspect-ratio: 4/3;
          position: relative;
          img {
            object-fit: cover;
          }
        }
      }
      &:after {
        margin: unset;
      }
    }
    @media (max-width: 360px) {
      .timeline {
        &-item {
          &_card {
            &_body {
              &_image {
                min-width: 8rem;
              }
            }
          }
        }
      }
    }
    @media (min-width: #{map-get($grid-breakpoints,lg)}) {
      &_card {
        &_body {
          &#body {
            padding: unset;
          }
          &[data-align='right'] {
            margin-inline-start: unset;
            margin-inline-end: 2.5rem;
            align-items: flex-end;
          }
          &_title#title {
            text-align: right;
            &[data-align='left'] {
              text-align: left;
            }
          }
          &_image {
            min-width: 20.5rem;
          }
        }
      }
    }
  }

  &_section {
    background-color: $background-dark;
    padding-block: 2rem 4rem;
    padding-inline: 1rem;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 53rem;
      margin: auto;
      &_text {
        display: flex;
        flex-direction: column;
        padding-block: 2.5rem 1.5rem;
        gap: 2rem;
        &_title {
          color: $title-color;
          text-align: center;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          margin-bottom: unset;
        }
        &_description {
          color: $text-color;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-block: 4rem 10rem;
      padding-inline: 4rem;
      &_container {
        &_text {
          max-width: 34.375rem;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,sm)}) {
    &_section_container_timeline {
      max-width: 50%;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    &_section_container_timeline {
      max-width: 100%;
    }
  }
}
.it-timeline-wrapper {
  .it-now-label {
    color: $pin-background-color-now;
    font-size: $font-size-medium;
    font-weight: 500;
  }
  .row::after {
    background: linear-gradient(
      0deg,
      hsla(179, 37%, 52%, 1),
      hsla(178, 33%, 38%, 1)
    );
    top: 2.8125rem;
  }
  .col-12:nth-child(odd) {
    .it-pin-wrapper {
      .pin-text {
        span:after {
          // right: -12px;
        }
      }
    }
    .it-now-label:before {
      background: $pin-background-color-now;
    }
  }
  .col-12:nth-child(even) {
    .it-now-label::after {
      background: $pin-background-color-now;
    }
  }
  .it-pin-wrapper {
    svg {
      width: 2rem;
      height: 2rem;
    }
    .pin-icon {
      border-width: 0.25rem;
    }
    .pin-text {
      color: $black;
      font-size: $font-size-medium;
      font-weight: 700;
      line-height: 1.5rem;
      background-color: $pin-background-color-future;
      span {
        padding: 0.5rem;
        &:after {
          // left: -12px;
          background-color: $pin-background-color-future;
        }
      }
    }
    &.it-evidence {
      .pin-icon {
        background-color: $pin-background-color-past;
      }
      .pin-text {
        background-color: $pin-background-color-past;
        color: $white;
        span:after {
          background-color: $pin-background-color-past;
        }
      }
    }
    &.it-now {
      .pin-icon {
        background-color: $pin-background-color-now;
      }
      .pin-text {
        background-color: $pin-background-color-now;
        color: $white;
        span:after {
          background-color: $pin-background-color-now;
        }
      }
    }
  }
}

// Chip
.chip {
  background-color: $teal;
  &.chip-lg {
    gap: 0.75rem;
    .chip-label {
      font-size: $font-size-medium;
      font-weight: 700;
      line-height: 1.5rem;
      color: $white;
    }
    button .icon {
      width: 1.25rem;
      fill: $white;
    }
    &:hover {
      background: $white;
      border-color: $teal;
      transition: all 0.3s;
      .chip-label {
        color: $teal;
      }
      button .icon {
        fill: $teal;
      }
    }
  }
}

// Pager
ul.pagination {
  margin: unset;
}
.custom-pager {
  &_number-row-mobile {
    & > ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
    gap: 1rem;
  }
  &_ctas {
    & > ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  &_item {
    &_dots {
      margin-block-start: 0.15rem;
    }
    &_previous {
      margin-inline-end: 2rem;
      font-size: $font-size-regular;
    }
    &_next {
      margin-inline-start: 2rem;
      font-size: $font-size-regular;
    }
    &_current {
      .page-link {
        padding: 1rem;
      }
    }
  }
}
.pagination .page-item {
  margin: unset;

  .page-link {
    color: $brownish-gray;
    font-size: $font-size-regular;
    line-height: 1.5rem;
    font-weight: 700;
    padding: 1.25rem 1rem;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &[aria-current] {
      border: 2px solid $teal;
      font-size: $font-size-regular;
      border-radius: 0.25rem;
      color: $dark-blue;
    }
  }
}

// SearchGrid
.search-grid {
  &_section {
    padding-inline: $mobile-inline-spacing;
    padding-block: $mobile-block-spacing;
    &_container {
      display: flex;
      flex-direction: column;
      max-width: 87.5rem;
      margin: 0 auto;
      &_paragraph {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-block: 2.5rem 1.5rem;
        max-width: 36.375rem;
        &_title {
          color: $title-color;
          text-align: left;
          font-size: $font-size-xxlarge;
          line-height: 2.5rem;
          font-weight: 700;
          margin-bottom: unset;
        }
        &_description {
          color: $text-color;
          font-size: $font-size-large;
          line-height: 2rem;
          font-weight: 400;
          margin-bottom: unset;
        }
      }
      &_search {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
        justify-content: space-between;
        gap: 1.5rem;
      }
      &_filter-wrapper {
        display: flex;
        flex-direction: column;
        padding-block: 2rem;
        gap: 1rem;
        &_filter-label {
          color: $brownish-gray;
          font-size: $font-size-regular;
          line-height: 1.5rem;
          font-weight: 700;
          margin-block-start: 1rem;
        }
        &_filters {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 1.5rem;
        }
        &_chips {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }
      &_results {
        display: flex;
        align-self: center;
        flex-direction: column;
        gap: 1rem;
        margin-block-end: 2rem;
        // min-height: 39.5rem;
        width: 100%;
        > li {
          width: 100%;
          &[data-flex-direction='row'] {
            height: 23.5rem;
          }
        }
        &_title {
          color: $dark-blue;
          font-size: $font-size-large;
          font-weight: 700;
          line-height: 2rem;
          margin-block: 2rem;
        }
      }
      &_pagination {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,md)}) {
    &_section {
      padding-inline: $desktop-inline-spacing;
      padding-block: $desktop-block-spacing;
      &_container {
        &_paragraph {
          max-width: 100ch;
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 3rem;
          }
          &_description {
            font-size: $font-size-xlarge;
            line-height: 2rem;
          }
        }
        &_search {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          &_button.btn-primary {
            width: max-content;
          }
        }
        &_results {
          // min-height: 45rem;
          row-gap: 2.5rem;
          &[data-flex-direction='row'] {
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            max-width: calc(22.5rem * 2);
            justify-content: flex-start;
            > li {
              width: calc((100% - 2rem) / 2);
              height: 23.5rem;
            }
          }
          &[data-flex-direction='column'] {
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;
          }
          &_title {
            font-size: $font-size-xxlarge;
            line-height: 2.5rem;
          }
        }
      }
    }
  }
  @media (min-width: #{map-get($grid-breakpoints,lg)}) {
    &_section_container_results[data-flex-direction='row'] {
      max-width: 87.5rem;
      > li {
        width: calc((100% - 3rem) / 3);
      }
    }
  }
}

// TextCollapser
.text-collapser {
  &-container {
    &-content {
      overflow: hidden;
    }
    &-button#show-more-button {
      margin-block-start: 0.75rem;
      color: $brownish-gray;
      font-size: $font-size-medium;
      line-height: 1.75rem;
      font-style: normal;
      font-weight: 700;
      text-decoration-line: underline;
    }
  }
}

.parallax {
  width: 100dvw;
  height: 100%;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

// BreadCrumbs
.breadcrumb {
  transition: all 0.3s;
  &-item:not(.active) {
    &:hover {
      scale: 1.025;
      filter: brightness(0.9);
    }
  }
}

.skip-links {
  position: relative;
  display: flex;
  height: 100%;
  z-index: 1000;
  .skip-link {
    position: absolute;
    top: auto;
    z-index: 100;
    transition: top 0.3s;
    text-decoration: underline;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    &:focus {
      position: static;
      top: 0;
      left: 0;
      width: auto;
      height: auto;
      padding: 0.5rem;
    }
  }
}
