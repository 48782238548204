// Per l'override del colore $primary della palette in formato HSB:
// Nota: per convertire il codice esadecimale di un colore formato HSB
// è possibile utilizzare ad esempio il sito https://rgb.to/.

// Theme colors
@import './custom/colors.module';

$primary-h: 178;
$primary-s: 34;
$primary-b: 36;
$primary: hsl($primary-h, $primary-s, $primary-b);

$primary-dark: hsl(211, 33%, 26%);
$primary-light: $primary;

$background-light: $light-beige;
$background-dark: $dark-beige;

// DOM Vars
$body-bg: $background-light;

// Font sizes
$font-size-xsmall: 0.75rem;
$font-size-small: 0.875rem;
$font-size-regular: 1rem;
$font-size-medium: 1.125rem;
$font-size-large: 1.25rem; // paragraph
$font-size-xlarge: 1.5rem; // text
$font-size-xxlarge: 2rem; // title
$font-size-xxxlarge: 2.5rem;
$font-size-display: 3rem;

// Section spacing
$mobile-inline-spacing: 1rem;
$mobile-block-spacing: 1.5rem;
$desktop-inline-spacing: 2.5rem;
$desktop-block-spacing: 3rem;

h1 {
  font-size: $font-size-xxxlarge !important;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// import lib
@import '../node_modules/bootstrap-italia//src//scss/bootstrap-italia.scss';
@import '../node_modules/bootstrap-italia//src//scss/variables.scss';

// custom
@import './custom/headers.module';
@import './custom/footer.module';
@import './custom/hero.module';
@import './custom/paragaph.module';
@import './custom/buttons.module';
@import './custom/richtext.module';
@import './custom/img-list.module';
@import './custom/avatar.module';
@import './custom/content-list.module';
@import './custom/cards.module';
@import './custom/slider.module';
@import './custom/accordion.module';
@import './custom/dropdown.module';
@import './custom/form.module';
@import './custom/generic.module';
@import './custom/page-loader.module';
@import './custom/animation.module';
@import './custom/pixel-decorator';
@import './custom/mixins.module';

// ? Breakpoints from Bootstrap Italia
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px,
